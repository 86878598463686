<div class="form-group">
  <div class="form-check">
    <input
      [id]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')"
      [formControl]="control"
      [required]="required"
      [readonly]="readonly"
      type="checkbox"
      class="form-check-input"
    >
    <label class="form-check-label" [for]="'field-component-' + formControlName + (controlContainer?.name ? '_' + controlContainer.name : '')">{{ label }}<span *ngIf="required"> *</span></label>
  </div>
  <validation-errors *ngIf="control?.invalid && (control?.dirty || control?.touched)" [errors]="control?.errors" [label]="label" class="invalid-feedback"></validation-errors>
  <small *ngIf="description" class="form-text text-muted">{{ description }}</small>
</div>
