import { Component, DestroyRef, Inject, Injector, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseField } from '../base-field';
import { ValidationErrorsComponent } from 'shared/components/forms/validation-errors/validation-errors.component';

@Component({
  selector: 'checkbox-field',
  templateUrl: './checkbox-field.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, ValidationErrorsComponent],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CheckboxFieldComponent, multi: true }]
})
export class CheckboxFieldComponent extends BaseField {

  @Input() description!: string;

  constructor(
    injector: Injector,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {
    super(injector, destroyRef);
  }
}
